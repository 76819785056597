<template>
  <div id="organizationalEdit">
    <div class="mainheader">
      <div class="headerBtn">
        <Button type="primary" @click="goBack">返回</Button>
        <Button @click="editMessage">编辑</Button>
        <!-- <Button>禁用</Button>
        <Button>删除</Button>
        <Button>重置密码</Button> -->
      </div>
    </div>
    <div class="mainContent">
      <div class="title">人员信息</div>
      <Form :model="formList" :rules="formRules" :label-width="80">
        <FormItem label="姓名" prop="ztuName">
          <div>{{ formList.ztuName }}</div>
        </FormItem>
        <FormItem label="员工编号" prop="ztuNumber">
          <div>{{ formList.ztuNumber }}</div>
        </FormItem>
        <FormItem label="角色">
          <div>{{ formList.ztrName.join(',') }}</div>
        </FormItem>
        <FormItem label="职务">
          <div>{{ formList.ztuPosition }}</div>
        </FormItem>
        <FormItem label="文化程度">
          <div>{{ formList.ztuEducationalLevel }}</div>
        </FormItem>
        <FormItem label="部门">
          <div>{{ formList.ztuDeptName }}</div>
        </FormItem>
        <FormItem label="性别">
          <div>{{ formList.ztuGender }}</div>
        </FormItem>
        <FormItem label="身份证号">
          <div>{{ formList.ztuCardid }}</div>
        </FormItem>
        <FormItem label="联系电话">
          <div>{{ formList.ztuPhone }}</div>
        </FormItem>
      </Form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formList: {
        ztuNumber: "", // 编号
        ztuName: "", // 姓名
        ztuPhone: "", // 联系电话
        ztuDeptName: "", // 所属部门
        ztuGender: "", // 性别
        ztuPosition: "", // 职务
        ztuCardid: "", // 身份证号码
        ztrName: "角色", // 角色
        ztuEducationalLevel: "" // 文化程度
      },
      formRules: {
        ztuName: [
          { required: true, message: "请填写员工姓名", trigger: "blur" }
        ],
        ztuNumber: [
          { required: true, message: "请填写员工编号", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    console.log(this.$parent.$parent.ztuId);
    this.getUserDetail();
  },
  methods: {
    getUserDetail() {
      this.axios({
        url: "/dtsum/zongtong/organization/UserManagerController/listPage",
        method: "get",
        params: {
          userId: this.$parent.$parent.ztuId
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.formList = res.data.body.list;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goBack() {
      this.$parent.$parent.organizationChange = 0;
    },
    editMessage() {
      this.$parent.$parent.organizationChange = 1;
    }
  }
};
</script>
<style lang="scss" scoped>
#organizationalEdit {
  font-family: Source Han Sans CN;
  .mainContent {
    padding: 35px 0px 24px 11px;
    .title {
      padding-bottom: 15px;
      border-bottom: 1px solid #dcdada;
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: 500;
      color: #212b36;
    }
    .ivu-input-wrapper {
      width: 400px;
    }
    .ivu-form-item-content {
      div {
        height: 34px;
        line-height: 34px;
      }
    }
  }
}
</style>
