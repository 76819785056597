<template>
  <div id="organizationalEdit">
    <div class="mainheader">
      <div class="headerBtn">
        <Button type="primary" @click="goBack">返回</Button>
        <Button @click="handleSubmit('formList')">保存</Button>
      </div>
    </div>
    <div class="mainContent">
      <div class="title">新增人员信息</div>
      <Form
        ref="formList"
        :model="formList"
        :label-width="80"
        :rules="formRules"
      >
        <FormItem label="姓名" prop="ztuName">
          <Input v-model="formList.ztuName"></Input>
        </FormItem>
        <FormItem label="员工编号" prop="ztuNumber">
          <Input v-model="formList.ztuNumber"></Input>
        </FormItem>
        <FormItem label="角色" prop="ztuZtrId">
          <Select
            v-model="formList.ztuZtrId"
            multiple
            collapse-tags
            style="width: 400px"
          >
            <Option
              v-for="item in roleList"
              :value="item.ztr_id"
              :key="item.ztr_id"
              >{{ item.ztr_name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="职务">
          <Input v-model="formList.ztuPosition"></Input>
        </FormItem>
        <FormItem label="文化程度">
          <Input v-model="formList.ztuEducationalLevel"></Input>
        </FormItem>
        <FormItem label="部门" prop="ztdeCode">
          <Select v-model="formList.ztdeCode" style="width: 400px">
            <Option
              v-for="item in departmentList"
              :value="item.ztdeCode"
              :key="item.ztdeCode"
              >{{ item.ztdeName }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="性别">
          <Select v-model="formList.ztuGender" style="width: 400px">
            <Option value="男">男</Option>
            <Option value="女">女</Option>
          </Select>
        </FormItem>
        <FormItem label="身份证号">
          <Input v-model="formList.ztuCardid"></Input>
        </FormItem>
        <FormItem label="联系电话">
          <Input v-model="formList.ztuPhone"></Input>
        </FormItem>
      </Form>
    </div>
  </div>
</template>
<script>
import Qs from "qs";
export default {
  data() {
    return {
      roleList: [],
      departmentList: [],
      formList: {
         ztuNumber: '',  
         ztuName: '',  
         ztuPhone: '', 
         ztdeCode: '', 
         ztuGender: '',  
         ztuPosition: '',  
         ztuProhibitedMarks: 1, 
         ztuCardid: '',  
         ztuZtrId: [], 
         ztuEducationalLevel: '' 
      },
      formRules: {
        ztuName: [
          { required: true, message: "请填写员工姓名", trigger: "blur" },
        ],
        ztuNumber: [
          { required: true, message: "请填写员工编号", trigger: "blur" },
        ],
        ztuZtrId: [
          {
            required: true,
            message: "请选择角色",
            trigger: "change",
            type: "array",
          },
        ],
        ztdeCode: [
          {
            required: true,
            type: "string",
            message: "请选择部门",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.getRoleList();
    this.getDepartmentList();
    this.getUserDetail();
  },
  methods: {
    goBack() {
      this.$parent.$parent.organizationChange = 0;
    },
    getUserDetail() {
      if (this.$parent.$parent.ztuId === "") {
        return false;
      }
      this.axios({
        url: "/dtsum/zongtong/organization/UserManagerController/listPage",
        method: "get",
        params: {
          userId: this.$parent.$parent.ztuId,
        },
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.formList = { ...res.data.body.list };
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((error) => {
          
          console.log(error);
        });
    },
    getRoleList() {
      this.axios({
        url: "/dtsum/zongtong/organization/RoleManagerController/searchRoleList",
        method: "get",
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.roleList = res.data.body.list;
          } else {
            this.$Message.error("角色筛选数据请求失败");
          }
        })
        .catch((error) => {
          console.log(`角色筛选数据:${error}`);
        });
    },
    getDepartmentList() {
      const _this = this;
      _this
        .axios({
          url: "/dtsum/zongtong/organization/DepartmentManagerController/department",
          method: "get",
        })
        .then((res) => {
          if (res.data.success === 1) {
            this.departmentList = res.data.body.list;
          }
        })
        .catch((error) => {
          console.log(`组织架构错误：${error}`);
        });
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (this.$parent.$parent.ztuId === "") {
            this.saveMessage();
          } else {
            this.editSaveMessage();
          }
        }
      });
    },
    saveMessage() {
      // console.log('111' + this.data.formList);
      this.axios({
        url: "/dtsum/zongtong/organization/UserManagerController/addUser",
        method: "post",
        headers: {
          // "Content-Type": "application/x-www-form-urlencoded",
          "Content-Type": "application/json",
        },
        data: this.formList,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("添加成功！");
            this.$parent.$parent.userList();
            this.$parent.$parent.department();
            this.$parent.$parent.organizationChange = 0;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editSaveMessage() {
      console.log(Qs);
      const formList = this.formList;
      formList.userId = this.$parent.$parent.ztuId;
      this.axios({
        url: "/dtsum/zongtong/organization/UserManagerController/updateUser",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: formList,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("编辑成功！");
            this.$parent.$parent.userList();
            this.$parent.$parent.department();
            this.$parent.$parent.organizationChange = 0;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
#organizationalEdit {
  font-family: Source Han Sans CN;
  .mainContent {
    padding: 35px 0px 24px 11px;
    .title {
      padding-bottom: 15px;
      border-bottom: 1px solid #dcdada;
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: 500;
      color: #212b36;
    }
    .ivu-input-wrapper {
      width: 400px;
    }
  }
}
</style>
