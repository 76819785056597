<template>
  <Content class="organization">
    <div class="main">
      <!-- 修改cardID弹窗 -->
      <Modal v-model="changeCardIdModal" draggable scrollable title="修改卡号">
        <Form
          ref="formCustom"
          :model="formCustom"
          :rules="ruleCustom"
          :label-width="80"
        >
          <FormItem label="ID卡号" prop="cardId">
            <Input type="text" v-model="formCustom.cardId"></Input>
          </FormItem>
          <FormItem label="" prop="cardId">
            <!-- <Input type="text" v-model="formCustom.cardId"></Input> -->
            <!-- <el-button type="primary" @click="getIdCard" style="margin-top:20px;">获取录入卡号</el-button> -->
          </FormItem>
        </Form>
        <div slot="footer">
          <Button
            @click="handleResetCard('formCustom')"
            style="margin-left: 8px"
            >取消</Button
          >
          <Button type="primary" @click="handleSubmitCard('formCustom')"
            >确定</Button
          >
        </div>
      </Modal>
      <!-- 搜索头部 开始 -->
      <!-- <div class="main-search">
        <div class="switchType">
          <div class="type">
            <Select v-model="userListData.searchType" style="width:108px">
              <Option
                v-for="(item, index) in searchChar"
                :value="item.type"
                :key="index"
                >{{ item.name }}</Option
              >
            </Select>
          </div>
          <div class="search">
            <Input
              suffix="ios-search"
              placeholder="请输入搜索内容"
              v-model="userListData.searchText"
              @on-change="userList"
              @on-blur="resetGetInfo"
              @on-enter="userList"
            />
          </div>
        </div>
        <div class="selectTool">
          <div class="name">角色</div>
          <Select
            v-model="userListData.roleId"
            style="width:108px"
            @on-change="resetGetInfo"
          >
            <Option
              v-for="item in roleList"
              :value="item.ayr_id"
              :key="item.ayr_id"
              >{{ item.ayr_name }}</Option
            >
          </Select>
        </div>
        <div class="btn-block" @click="exportExcel">
          <i class="icon1 icondaochu"></i>
          <span>导出</span>
        </div>
      </div> -->
      <!-- 搜索头部 结束 -->

      <!-- 下方表格以及树形 开始 -->
      <div class="maincontent orz">
        <div class="main-table">
          <!-- 表头部分 开始 -->

          <!-- 表头部分 结束 -->

          <div class="table-box">
            <!-- 组织架构 树形 开始 -->
            <div>
              <div class="mainheader">
                <div class="title">
                  <span class="click-all" @click="allList"
                    >组织架构({{ totalUser }})</span
                  >
                  <i
                    class="iconfont iconicon-zengjia"
                    style="cursor: pointer"
                    @click.stop="() => appendFather()"
                  ></i>
                </div>
                <!-- <div class="title">
              {{ departName === "" ? "所有部门" : departName }}
            </div> -->
              </div>
              <div class="tree" v-if="treeData.length !== 0">
                <el-tree
                  :data="treeData"
                  :props="defaultProps"
                  node-key="id"
                  @node-click="handleNodeClick"
                  :expand-on-click-node="false"
                >
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{ node.label }}({{ data.allPeople }})</span>
                    <span class="insert-btn">
                      <!-- <i
                        v-if="node.level === 1 || node.childNodes.length !== 0"
                        class="iconfont iconicon-zengjia"
                        @click.stop="() => appendChild(node, data)"
                      ></i> -->
                      <i
                        class="iconfont iconicon-bianji"
                        @click.stop="() => editTree(node, data)"
                      ></i>
                      <i
                        class="iconfont iconicon-shanchu"
                        @click.stop="() => remove(node, data)"
                      ></i>
                    </span>
                    <!-- <span>({{ node }})</span> -->
                  </span>
                </el-tree>
              </div>
              <div class="tree" v-else>
                <p>暂无数据</p>
              </div>
            </div>

            <!-- 组织架构 树形 结束 -->
            <!-- 用户列表 开始 -->
            <div class="table" v-if="organizationChange === 1">
              <OrganizationalEdit></OrganizationalEdit>
            </div>
            <div
              class="table"
              v-if="organizationChange === 2 || organizationChange === 3"
            >
              <OrganizationalDetail></OrganizationalDetail>
            </div>
            <div class="table" v-if="organizationChange == 0">
              <div class="mainheader">
                <div class="headerSearch">
                  <div class="switchType">
                    <div class="type">
                      <Select
                        v-model="userListData.searchType"
                        style="width:117px"
                      >
                        <Option
                          v-for="(item, index) in searchChar"
                          :value="item.type"
                          :key="index"
                          >{{ item.name }}</Option
                        >
                      </Select>
                    </div>
                    <div class="search">
                      <Input
                        suffix="ios-search"
                        placeholder="请输入搜索内容"
                        v-model="userListData.searchText"
                        @on-blur="resetGetInfo"
                        @on-enter="userList"
                      />
                    </div>
                  </div>

                  <div class="selectTool">
                    <div class="name">角色</div>
                    <div class="type">
                      <Select
                        v-model="userListData.roleId"
                        style="width:100px"
                        @on-change="resetGetInfo"
                      >
                        <Option
                          v-for="item in roleList"
                          :value="item.ztr_id"
                          :key="item.ztr_id"
                          >{{ item.ztr_name }}</Option
                        >
                      </Select>
                    </div>
                  </div>
                </div>
                <div class="headerBtn">
                  <Button
                    type="primary"
                    @click="
                      organizationChange = 1;
                      ztuId = '';
                    "
                    >新增</Button
                  >
                  <Button @click="removeUser">删除</Button>
                </div>
              </div>
              <el-table
                empty-text=" "
                height="90%"
                :data="tableData"
                border
                style="width: 100%"
                :header-cell-style="headerStyle"
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="40"> </el-table-column>
                <el-table-column label="序号" width="74" fixed>
                  <template slot-scope="scope">
                    <span>{{
                      (userListData.pageNum - 1) * userListData.pageSize +
                        scope.$index +
                        1
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  fixed
                  prop="ztuNumber"
                  label="员工编号"
                  min-width="104"
                ></el-table-column>
                <el-table-column
                  fixed
                  prop="ztuName"
                  label="姓名"
                  min-width="100"
                ></el-table-column>
                <el-table-column label="操作" width="120">
                  <template slot-scope="scope">
                    <el-button
                      @click="resetPW(scope.row.ztuId)"
                      type="text"
                      size="small"
                      >重置密码</el-button
                    >
                    <el-button
                      @click="
                        organizationChange = 2;
                        ztuId = scope.row.ztuId;
                      "
                      type="text"
                      size="small"
                      >修改</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column label="状态" width="150">
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.abandonSign"
                      active-color="#13ce66"
                      active-text="开启"
                      inactive-text="禁用"
                      :active-value="1"
                      :inactive-value="0"
                      @change="abandonU(scope.row.ztuId, scope.row.abandonSign)"
                      :disabled="scope.row.ztuId == 1"
                    ></el-switch>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="roleIdList"
                  label="角色"
                  min-width="190"
                  :show-overflow-tooltip="true"
                >
                  <template slot-scope="scope">
                    <Select
                      v-model="scope.row.roleIdList"
                      multiple
                      collapse-tags
                      @on-change="
                        changeRole(scope.row.ztuId, scope.row.roleIdList)
                      "
                      :disabled="scope.row.ztuId == 1"
                    >
                      <Option
                        v-for="(item, index) in roleNameArr"
                        :value="item.ztr_id"
                        :key="index"
                        >{{ item.ztr_name }}</Option
                      >
                    </Select>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="ztuPosition"
                  label="职务"
                ></el-table-column>
                <el-table-column
                  prop="ztuEducationalLevel"
                  label="文化程度"
                ></el-table-column>
                <el-table-column
                  prop="ztuDeptName"
                  label="部门"
                  min-width="150"
                >
                  <template slot-scope="scope">
                    <!--  <el-button @click="dataView(scope.row)" type="text" size="small">查看</el-button> -->
                    <Select
                      v-model="scope.row.ztuZtdeCode"
                      @on-change="
                        changeDep(scope.row.ztuZtdeCode, scope.row.ztuId)
                      "
                    >
                      <Option
                        v-for="(item, index) in departNameArr"
                        :value="item.ztdeCode"
                        ref="clearClick"
                        :key="index"
                        >{{ item.ztdeName }}</Option
                      >
                    </Select>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  prop="ztuIccardnum"
                  label="ID卡号"
                  min-width="130"
                >
                  <template slot-scope="scope">
                    <div
                      @click="
                        editCardId(scope.row.ztuIccardnum, scope.row.ayuId)
                      "
                    >
                      {{
                        scope.row.ztuIccardnum == "" ||
                        scope.row.ztuIccardnum == null
                          ? "暂无"
                          : scope.row.ztuIccardnum
                      }}
                    </div>
                  </template>
                </el-table-column> -->
                <el-table-column label="性别">
                  <template slot-scope="scope">
                    {{ scope.row.ztuGender }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="ztuCardid"
                  label="身份证号"
                  min-width="212"
                ></el-table-column>
                <el-table-column
                  prop="ztuPhone"
                  label="联系电话"
                  min-width="143"
                ></el-table-column>
                <el-table-column
                  label="操作日志"
                  prop="ztuOperationLog"
                ></el-table-column>
              </el-table>
            </div>
            <!-- 用户列表 结束 -->
          </div>
        </div>
        <div
          v-if="organizationChange == 0"
          class="main-page"
          style="display: flex;justify-content: center;padding-left: 20%;"
        >
          <Page
            ref="pages"
            :total="userListData.total"
            show-sizer
            show-elevator
            show-total
            :page-size="userListData.pageSize"
            :current="userListData.pageNum"
            @on-page-size-change="pagesizechange"
            @on-change="pagenumberchange"
          />
        </div>
      </div>
      <!-- 下方表格以及树形 结束 -->
    </div>
    <!-- 删除 弹窗 -->
    <Modal v-if="isPeople" v-model="modalDelete" title="删除" width="440">
      <div>
        <i class="iconfont iconicon-gantanhao modalGantanhao"></i
        >需要移除部门下的人员，才可以进行删除。
      </div>
      <!-- <div class="modalDelMessage">需要移除部门下的人员，才可以进行删除。</div> -->
      <div slot="footer">
        <Button @click="modalDelete = false" class="white-btn">取消</Button>
        <Button type="primary" @click="modalDelete = false">确定</Button>
      </div>
    </Modal>
    <Modal v-else v-model="modalDelete" title="删除" width="440">
      <div style="margin-bottom:17px">
        <i class="iconfont iconicon-gantanhao modalGantanhao"></i
        >是否确定删除该部门？
      </div>
      <div class="modalDelMessage">
        删除操作不可撤销，删除的内容将不可恢复。
      </div>
      <div slot="footer">
        <Button @click="modalDelete = false" class="white-btn">取消</Button>
        <Button type="primary" @click="removeDep">确定</Button>
      </div>
    </Modal>
    <!-- 添加部门弹窗 -->
    <Modal v-model="modalFlagAppend" title="添加部门" width="440">
      <Form
        ref="appendForm"
        :label-width="80"
        :model="departForm"
        :rules="departRule"
      >
        <FormItem label="部门名称" prop="name">
          <Input
            autofocus
            ref="focus"
            style="width: 280px"
            v-model.trim="departForm.name"
            placeholder="请输入名称，不超过 10 个字"
            :maxlength="10"
          />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="handleReset" class="white-btn">取 消</Button>
        <Button type="primary" @click="addDepartment('appendForm')"
          >确 定</Button
        >
      </div>
    </Modal>
    <!-- 编辑部门弹框 -->
    <Modal v-model="modalEdit" title="编辑部门" width="440">
      <Form
        ref="appendForm"
        :label-width="80"
        :model="departForm"
        :rules="departRule"
      >
        <FormItem label="部门名称" prop="name">
          <Input
            style="width: 280px"
            v-model.trim="departForm.name"
            placeholder="请输入名称，不超过 10 个字"
            :maxlength="10"
          />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="handleReset2" class="white-btn">取 消</Button>
        <Button type="primary" @click="addEditConfirm('appendForm')"
          >确 定</Button
        >
      </div>
    </Modal>
    <Modal v-model="modalReset" title="重置密码" width="440">
      <div>
        此操作将重置密码(123456), 是否继续?
      </div>
      <div slot="footer">
        <Button @click="modalReset = false" class="white-btn">取消</Button>
        <Button type="primary" @click="realResetPW">确定</Button>
      </div>
    </Modal>
  </Content>
</template>

<script>
// import { useaxios } from "@/assets/js/axios";
// import { url_api, format_time } from "@/assets/js/common";
// import { log } from "three";
import OrganizationalEdit from "../../../components/systemManage/organizationalEdit";
import OrganizationalDetail from "../../../components/systemManage/organizationalDetail";
import Qs from "qs";
export default {
  components: { OrganizationalEdit, OrganizationalDetail },
  data() {
    return {
      organizationChange: 0, // 0:列表  1：新增  2：详情  3：编辑
      modalReset: false,
      reset: "",
      roleNameArr: [], // 角色列表
      searchChar: [],
      roleList: [],
      ztuId: "", // 用户id
      isCard: [
        {
          label: "全部",
          value: "-1"
        },
        {
          label: "需要",
          value: "Y"
        },
        {
          label: "不需要",
          value: "N"
        }
      ],
      treeData: [],
      modalDelete: false,
      modalFlagAppend: false,
      modalEdit: false,
      isPeople: true,
      ztdeCode: "",
      tableData: [],
      userListData: {
        searchType: 2,
        searchText: "",
        roleId: "-1",
        ztdeCode: "",
        pageSize: 10,
        pageNum: 1,
        total: 0,
        page: 0
      },
      departForm: {
        name: ""
      },
      departName: "",
      departRule: {
        name: [
          { required: true, trigger: "blur", message: "此项必填！" },
          { type: "string", max: 10, message: "名称不能超过 10 个字符" }
        ]
      },
      addProviderModal: false,
      departNameValue: "",
      departNameArr: [],
      defaultProps: {
        children: "departmentList",
        label: "ztdeName"
      },
      changeCardIdModal: false, // 修改ID卡号弹窗
      formCustom: {
        cardId: "",
        userId: ""
      },
      ruleCustom: {
        cardId: [{ required: true, message: "卡号ID不能为空", trigger: "blur" }]
      },
      userListId: [],
      totalUser: 0 // 人员总数
    };
  },
  methods: {
    resetGetInfo() {
      this.userListData.pageSize = 10;
      this.userListData.pageNum = 1;
      this.userList();
    },
    handleSubmitCard(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          // useaxios
          //   .axios(
          //     this.$api.baseUrl +
          //       "/iext/back/organization/UserManagerController/editUserCard",
          //     {
          //       userId: this.formCustom.userId,
          //       cardCode: this.formCustom.cardId
          //     },
          //     "Get"
          //   )
          //   .then((res) => {
          //     if (res.data.success == 1) {
          //       this.$Message.success("修改成功!");
          //       this.userList();
          //       this.changeCardIdModal = false;
          //     } else {
          //       this.$Message.error(res.data.msg);
          //     }
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
        } else {
          this.$Message.error("输入卡号有误！");
        }
      });
    },
    handleResetCard(name) {
      this.changeCardIdModal = false;
      this.$refs[name].resetFields();
    },
    // 修改id卡号
    editCardId(userIcCardNum, userId) {
      // this.$Message.success(userIcCardNum);
      this.formCustom.cardId = userIcCardNum;
      this.formCustom.userId = userId;
      this.changeCardIdModal = true;
    },
    // 表格多选框
    handleSelectionChange(e) {
      const arr = [];
      e.map((item) => {
        arr.push(item.ztuId);
      });
      this.userListId = arr;
    },
    removeUser() {
      if (this.userListId.length === 0) {
        this.$Message.warning("请先选择要删除的用户");
        return false;
      }
      this.axios({
        url: "/dtsum/zongtong/organization/UserManagerController/deleteUser",
        method: "get",
        params: {
          ztuId: this.userListId.toString()
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("删除成功！");
            this.userList();
            this.department();
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 改变角色
    changeRole(userId, roleId) {
      var data = {
        userId: userId,
        roleIdList: roleId
      };
      if (roleId.length === 0) {
        data = {
          userId: userId,
          roleIdList: ""
        };
      }
      if (roleId) {
        this.axios({
          url:
            "/dtsum/zongtong/organization/UserManagerController/editUserRole",
          method: "get",
          params: data,
          paramsSerializer: function(params) {
            return Qs.stringify(params, { arrayFormat: "repeat" });
          }
        })
          .then((res) => {
            if (res.data.success === 1) {
            } else {
              this.$Message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // 获取角色列表
    getRoleList() {
      const that = this;
      that
        .axios({
          url:
            "/dtsum/zongtong/organization/RoleManagerController/searchRoleList",
          method: "get"
        })
        .then((res) => {
          if (res.data.success === 1) {
            that.roleNameArr = res.data.body.list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /* 搜索条部分 开始 */
    // 搜索字段数据
    searchList() {
      this.axios({
        url: "/dtsum/zongtong/organization/UserManagerController/searchList",
        method: "get"
      })
        .then((res) => {
          if (res.data.success === 1) {
            const data = res.data.body.list;
            this.searchChar = data;
          }
        })
        .catch((error) => {
          console.log(`搜索字段设置:${error}`);
        });
    },
    // 角色筛选数据
    searchRoleList() {
      this.axios({
        url:
          "/dtsum/zongtong/organization/RoleManagerController/searchRoleList",
        method: "get"
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.roleList = res.data.body.list;
            this.roleList.unshift({
              ztr_id: "-1",
              ztr_name: "全部"
            });
          } else {
            this.$Message.error("角色筛选数据请求失败");
          }
        })
        .catch((error) => {
          console.log(`角色筛选数据:${error}`);
        });
    },
    // 导出表格
    exportExcel() {
      window.location.href = `${this.baseUrl}/dtsum/zongtong/organization/UserManagerController/exportUserList`;
    },
    /* 搜索条部分 结束 */

    /* 组织架构树形 开始 */
    // 组织架构树形数据
    department() {
      const _this = this;
      _this
        .axios({
          url:
            "/dtsum/zongtong/organization/DepartmentManagerController/department",
          method: "get"
        })
        .then((res) => {
          if (res.data.success === 1) {
            _this.treeData = res.data.body.list;
            this.departNameArr = res.data.body.list;
            _this.totalUser = res.data.body.userCoount.totalUser;
            for (const i in _this.treeData) {
              _this.treeData[i].allPeople = _this.treeData[i].numberOfPeople;
              if (
                _this.treeData[i].departmentList &&
                _this.treeData[i].departmentList.length !== 0
              ) {
                const childData = _this.treeData[i].departmentList;
                for (const j in childData) {
                  if (childData[j] && childData[j] !== 0) {
                    childData[j].allPeople = childData[j].numberOfPeople;
                    childData[j].departmentList = [];
                  } else {
                    childData[j].allPeople = 0;
                  }
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log(`组织架构错误：${error}`);
        });
    },
    // 点击节点所做操作
    handleNodeClick(data) {
      console.log(data);
      this.ztdeCode = data.ztdeCode;
      this.departName = data.ztdeName;
      this.userListData.ztdeCode = data.ztdeCode;
    },
    // 点击组织架构四个字列表查询结果变为全部
    allList() {
      this.departName = "";
      this.userListData.ztdeCode = "";
    },
    // 新建部门
    appendFather() {
      this.ztdeCode = "";
      this.modalFlagAppend = true;
      this.departForm.name = "";
    },
    appendChild(node, data) {
      console.log(data);
      // console.log(data);
      // console.log(scope.row)
      this.modalFlagAppend = true;
      this.ztdeCode = data.ztdeCode;
      this.departForm.name = "";
    },
    // 确认新建 修改
    addEditConfirm(name) {
      this.$refs[name].validate((valid) => {
        if (valid && this.departForm.name !== "组织架构") {
          // 如果部门 id 不为空，则为编辑 如果为空 则为新建
          console.log(this.ztdeCode);
          if (this.ztdeCode !== "") {
            const params = {
              ztdeCode: this.ztdeCode,
              name: this.departForm.name
            };
            // console.log( name)
            this.axios({
              url:
                "/dtsum/zongtong/organization/DepartmentManagerController/editName",
              method: "get",
              params: params
            })
              .then((res) => {
                if (res.data.success === 1) {
                  this.$Message.success("修改成功!");
                  this.modalEdit = false;
                  this.department();
                  this.changeDepName();
                  this.userList();
                } else {
                  this.$Message.error(res.msg);
                }
              })
              .catch((error) => {
                console.log(`修改名称:${error}`);
              });
          } else {
            this.$Message.warning("名称重复！");
          }
        } else {
          this.$Message.error("添加失败！名称重复或不符合规则！");
        }
      });
    },
    // 点击取消 关闭新增弹框
    handleReset() {
      this.modalFlagAppend = false;
    },
    // 点击取消，关闭修改弹框
    handleReset2() {
      this.modalEdit = false;
    },
    // 新增部门
    addDepartment(name) {
      this.$refs[name].validate((valid) => {
        if (valid && this.departForm.name !== "组织架构") {
          const params = {
            ztdeCode: this.ztdeCode === "" ? undefined : this.ztdeCode,
            name: this.departForm.name
          };
          this.axios({
            url:
              "/dtsum/zongtong/organization/DepartmentManagerController/addDepartment",
            method: "get",
            params: params
          })
            .then((res) => {
              // console.log(res)
              if (res.data.success === 1) {
                this.$Message.success("新增成功");
                this.changeDepName();
                this.department();
                this.handleReset();
              } else {
                this.$Message.error(res.data.msg);
                this.departForm.name = "";
                this.$refs.focus.focus();
              }
            })
            .catch((error) => {
              console.log(`新增部门${error}`);
            });
        } else {
          this.$Message.error("添加失败！名称重复或不符合规则！");
        }
      });
    },
    // 编辑节点
    editTree(node, data) {
      this.modalEdit = true;
      this.departForm.name = data.ztdeName;
      this.ztdeCode = data.ztdeCode;
      // console.log(this.departForm.name);
      // console.log(this.ztdeCode);
    },
    // 移除节点
    remove(data) {
      this.ztdeCode = data.data.ztdeCode;
      // console.log(this.ztdeCode);
      this.modalDelete = true;
      this.department();
      if (
        data.data.numberOfPeople ||
        (data.data.departmentList && data.data.departmentList.length > 0)
      ) {
        this.isPeople = true;
      } else {
        this.isPeople = false;
      }
    },
    // 删除部门
    removeDep() {
      if (this.ztdeCode) {
        const params = {
          ztdeCode: this.ztdeCode
        };
        this.axios({
          url:
            "/dtsum/zongtong/organization/DepartmentManagerController/delete",
          method: "get",
          params: params
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.$Message.success("部门删除成功！");
              this.modalEdit = false;
              this.modalDelete = false;
              this.department();
              this.changeDepName();
            } else {
              this.$Message.error(res.data.msg);
            }
          })
          .catch((error) => {
            console.log(`部门删除:${error}`);
          });
      }
    },
    /* 组织架构树形 结束 */
    // 调试 查看每行数据
    // 表格中修改部门名称
    changeDep(ztdeCode, userId) {
      if (ztdeCode === undefined) {
        return;
      }
      const params = {
        userId: userId,
        ztdeCode: ztdeCode
      };
      // console.log(params);
      this.axios({
        url: "/dtsum/zongtong/organization/UserManagerController/editUser",
        method: "get",
        params: params
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("修改成功");
            this.department();
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(`角色修改失败:${error}`);
        });
    },
    // 修改表头的颜色
    headerStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return (
          "background-color: #F5F5F5;" +
          "color: #333;" +
          "font-weight: 600;" +
          "font-size: 14px;" +
          "font-family: Source Han Sans CN;" +
          "height:50px"
        );
      }
    },
    // 查询名称列表
    changeDepName() {
      this.axios({
        url:
          "/dtsum/zongtong/organization/DepartmentManagerController/searchSearchDeptList",
        method: "get"
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.departNameArr = res.body.list;
          } else {
            this.$Message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(`查询部门名称:${error}`);
        });
    },

    // 用户列表数据
    userList() {
      const params = {
        searchType:
          this.userListData.searchType === ""
            ? undefined
            : this.userListData.searchType,
        searchText:
          this.userListData.searchText === ""
            ? undefined
            : this.userListData.searchText.replaceAll(" ", ""),
        roleId:
          this.userListData.roleId === "-1"
            ? undefined
            : this.userListData.roleId,
        ztdeCode:
          this.userListData.ztdeCode === ""
            ? undefined
            : this.userListData.ztdeCode,
        pageSize: this.userListData.pageSize,
        pageNum: this.userListData.pageNum
      };
      this.axios({
        url: "/dtsum/zongtong/organization/UserManagerController/userList",
        method: "get",
        params: params
      })
        .then((res) => {
          if (res.data.success === 1) {
            const data = res.data.body.list;
            this.tableData = data.list;
            console.log(this.tableData);
            this.userListData.total = data.total;
            this.userListData.pageSize = data.pageSize;
            this.userListData.pageNum = data.pageNum;
            this.userListData.page = data.pageNum;
          }
        })
        .catch((error) => {
          console.log(`用户列表数据:${error}`);
        });
    },
    // 重置密码弹框
    resetPW(reset) {
      this.reset = reset;
      this.modalReset = true;
    },
    realResetPW() {
      const params = {
        userId: this.reset
      };
      this.axios({
        url: "/dtsum/zongtong/organization/UserManagerController/resetPassword",
        method: "get",
        params: params
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("密码重置成功(123456)！");
          } else {
            this.$Message.error("密码重置失败");
          }
          this.modalReset = false;
        })
        .catch((error) => {
          console.log(`密码重置:${error}`);
        });
    },
    // 禁用员工
    abandonU(id, value) {
      // console.log(value, typeof value);
      const params = {
        userId: id,
        ztuProhibitedMarks: value
      };
      this.axios({
        url: "/dtsum/zongtong/organization/UserManagerController/abandonUser",
        method: "get",
        params: params
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("操作成功！");
          } else {
            this.$Message.warning("操作失败！");
          }
        })
        .catch((error) => {
          console.log(`禁用员工:${error}`);
        });
    },
    // 修改每页条数
    pagesizechange(pagesize) {
      this.userListData.pageSize = pagesize;
      this.userListData.pageNum = 1;
      this.userList();
    },

    // 修改页码
    pagenumberchange(pagenum) {
      this.userListData.pageNum = pagenum;
      this.userList();
    }
  },
  created() {
    this.department();
    this.userList();
    this.searchList();
    this.searchRoleList();
    this.changeDepName();
    this.getRoleList();
  },
  mounted() {},
  watch: {
    "userListData.ztdeCode": {
      handler(newVal, oldVal) {
        this.userListData.pageNum = 1;
        this.userList();
      }
    },
    deep: true
  }
};
</script>

<style lang="scss" scoped>
@function px2rem($px) {
  $rootFontSize: 192px;
  @return ($px / $rootFontSize) + rem;
}
.el-message-box .el-message-box__content {
  padding: 0px;
}
.organization {
  .main {
    .main-search {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .switchType {
        /*float: left;*/
        width: 300px;
        // height: $newIpt-height;
        .type,
        .search {
          float: left;
        }
        .type {
          width: 98px;
          height: 100%;
        }
        .search {
          width: 202px;
          .ivu-input-wrapper {
            width: 202px;
          }
        }
      }
      // .selectTool {
      //   width: 200px;
      //   height: $newIpt-height;
      //   display: flex;
      //   line-height: $newIpt-height;
      //   .name {
      //     height: $newIpt-height;
      //     line-height: $newIpt-height;
      //     margin-right: 10px;
      //   }
      // }
      .btn-block {
        height: px2rem(30px);
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        text-align: center;
        // .icon-box {
        //   background: url("/static/images/aoya/Export.png");
        // }
      }
      .btn-block:hover {
        color: #2980b9;
      }
      // .icon-box:hover {
      //   background: url("../../../../static/images/hover/export.png");
      // }
    }
    .maincontent {
      .main-table {
        width: 100%;
        height: 78vh;
        .mainheader {
          background: #f5f5f5 !important;
          display: flex;
          .title {
            font-size: 16px;
            font-family: PingFangSC;
            font-weight: 500;
            color: rgba(51, 51, 51, 1);
            display: flex;
            flex-direction: row;
            align-items: center;
            .click-all {
              width: 162px;
              cursor: pointer;
            }
          }
        }
        .table-box {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: left;
          .tree {
            width: 210px;
            height: 100%;
            overflow: hidden;
            overflow-y: scroll;
            background-color: #f5f5f5;
          }
          .tree::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
            height: 6px;
          }
          .tree::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 5px;
            box-shadow: inset 0 0 5px #2980b9;
            background: #2980b9;
          }
          .tree::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
            border-radius: 0;
            background: rgba(0, 0, 0, 0);
          }
          .custom-tree-node {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            padding-right: 8px;
            .insert-btn {
              display: inline-block;
              opacity: 0;
              height: 100%;
              display: flex;
              align-items: center;
              i {
                margin-right: 3px;
              }
            }
          }
          .insert-btn:hover {
            opacity: 1;
          }
          .table {
            width: calc(100% - 280px);
            margin-left: 15px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.gutter {
  display: block !important;
  width: 17px !important;
}
.modalGantanhao {
  color: #ff1414;
  margin-right: 12px;
}
.organization {
  .mainheader {
    display: flex;
    justify-content: space-between;
    line-height: 52px;
    padding: 0 16px;
    .el-icon-circle-plus:before {
      color: rgba(99, 115, 129, 1);
    }
    .headerBtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ivu-btn {
        width: 60px;
        height: 32px;
        border-radius: 4px;
        padding: 0px;
        margin-right: 15px;
      }
    }
    .headerSearch {
      display: flex;
      .ivu-select-selection,
      .ivu-input,
      .ivu-select-selected-value {
        height: 32px;
        line-height: 32px;
      }
      .switchType {
        display: flex;
        margin-right: 20px;
      }
      .selectTool {
        display: flex;
        .name {
          margin-right: 10px;
          min-width: 30px;
        }
      }
    }
  }

  .orz {
    .main-table {
      .table-box {
        .el-tree {
          background-color: #f5f5f5;
        }
      }
    }
  }
  .table-box {
    .el-tree-node:focus > .el-tree-node__content {
      background: rgba(242, 245, 248, 1) !important;
      color: rgba(41, 128, 185, 1);
    }
    .el-tree-node__content {
      height: 50px;
      border: 0 none;
      // .el-tree-node__content:hover{
      //   opacity: 1 ;
      // }
      .el-switch.is-checked .el-switch__core {
        background-color: #2980b9 !important;
      }
    }
    .el-tree-node__content:hover .insert-btn {
      opacity: 1 !important;
    }
    .custom-tree-node {
      .el-icon-circle-plus:before {
        color: rgba(99, 115, 129, 1);
      }
      .el-icon-edit:before {
        color: rgba(99, 115, 129, 1);
      }
      .el-icon-delete-solid:before {
        color: rgba(99, 115, 129, 1);
      }
    }
  }
  .table {
    .el-table th.gutter {
      display: table-cell !important;
    }
    .ivu-select-selection {
      // border: 0 none;
      border-radius: 0;
    }
  }
  .el-table .cell {
    white-space: nowrap; //强制不换行
    overflow: hidden; //溢出隐藏
    text-overflow: ellipsis; //替换为省略号
  }
  .ivu-select-multiple .ivu-select-selection div {
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
